.parceiros {
 
}

.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.parceiros-title {
  text-align: center;
  font-weight: bold;
  margin-bottom: 20px;
  font-family: 'Roboto', sans-serif;
}

.card {
  position: relative;
  width: 200px;
  height: 250px;
  margin: 10px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 10px #3BB1D6;
  transition: transform 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #3BB1D6;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.card:hover .overlay {
  opacity: 1;
}

.circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.circle-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 3px solid transparent; /* Adiciona uma borda transparente ao redor da imagem */
}

.circle-image:hover {
  border-color: #3BB1D6; /* Muda a cor da borda ao passar o mouse sobre a imagem */
}

.tip {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  text-align: center;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
}

.second-text {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  text-align: center;
  color: #fff;
  font-size: 14px;
}

.idfederal{
  width: 180%;
}

.elgin{
  width: 180%;
}