.servicos{
  
}

.servicos-container {
 
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px; /* Adiciona um espaço entre os cards */
  margin-bottom: 20px;
}

.servico-card {
  text-align: center;
  border: 1px solid #3498db;
  border-radius: 15px;
  padding: 10px; /* Reduz o espaço interno dos cards */
  transition: transform 0.3s;
  flex: 1 1 250px; /* Faz os cards crescerem ou encolherem conforme o espaço disponível */
  max-width: 180px; /* Define a largura máxima dos cards */
}

.servico-card:hover {
  transform: scale(1.05); /* Reduz um pouco a escala no hover */
}

.servico-card p {
  margin-top: 5px;
}

h2 {
  text-align: center;
  color: #2c2b29;
  margin-bottom: 30px;
  margin-top: 80px;
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
}

.subtitle {
  text-align: center;
  color: #2c2b29;
  margin-bottom: 30px;
  margin-top: 10px;
  font-size: medium;
  font-family: 'Roboto', sans-serif;
}
