.rodape {
  background-color: #383838;
  color: #FFF;
  padding-top: 25px; /* Adiciona espaço ao topo, semelhante ao margin-top em .copyrights */
}

.rodape-section-3 {
  padding: 1rem 0;
}

.rodape-text {
  font-size: 0.875rem;
  text-align: center;
}

.dark-line {
  border-top: 1px solid #525252;
}

a.link {
  color: #008acc;
  border: 1px #008acc solid;
  padding: 5px;
  text-decoration: none;
}

a.link:hover {
  background: #008acc;
  color: #383838;
}

.footimg {
  width: 5rem;
}
