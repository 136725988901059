.cabecalho-container {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}

.logo {
  max-width: 100px;
  max-height: 200px;
  border-color: black;
  transform: scale(2);
  transition: transform 0.3s ease; /* Suaviza a transição da posição */
}

.navbar {
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
}

.logo,
.navbar,
.navbar .nav-link {
  font-family: 'Montserrat', sans-serif;
}

.navbar .nav-link {
  color: rgb(66, 63, 63);
  background-color: transparent;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  border-bottom-right-radius: 20px;
  border-top-left-radius: 20px;
  font-weight: 900;
  opacity: 1;
  margin-right: 10px;
}

.navbar .nav-link:hover {
  background-color: #3BB1D6;
  color: white;
  opacity: 0.8;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.392);
}

@media (max-width: 991.98px) {
  .navbar .navbar-nav .nav-link {
    text-align: center;
  }

  /* Ajuste a logo para se mover para a esquerda em telas menores */
  .logo {
    transform: scale(1.5); /* Reduz a escala se necessário */
    margin-left: 40px; /* Ajusta a margem esquerda para a logo */
    width: 180px;
  }

  /* Altere o comportamento do subcabeçalho em telas menores */
  .sub-cabecalho {
    display: none; /* Oculta o sub-cabeçalho em telas pequenas */
  }
}

.navbar .navbar-nav {
  margin-left: auto;
}

.custom-nav-link:hover {
  color: white !important;
}

.sub-cabecalho {
  background-color: #000000;
  height: 25px;
  width: 100%;
}

.tags-2,
.tags-1,
.tags-3 {
  color: #ffffff;
  font-weight: 600;
  font-size: 12px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.tags-1 {
  left: 100px;
}

.tags-2 {
  right: 150px;
}

.tags-3 {
  left: 50%;
  transform: translate(-50%, -50%);
}
