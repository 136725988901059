/* Estilo do arquivo: Carrosel */

.carousel-caption {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.7vw; /* Ajuste dinâmico para responsividade */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.839);
  display: flex;
  flex-direction: column;
  justify-content:center;/* Centraliza verticalmente */
  align-items: center; /* Centraliza horizontalmente */
  height: 100%; /* Ocupa 100% da altura do item do carrossel */
  padding: 20px; /* Espaçamento interno */
}

.carousel-caption h3 {
  font-size: 2.8em; /* Tamanho do título */
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 0.5rem; /* Espaçamento entre título e texto */
}

.carousel-caption p {
  font-size: 1.5em; /* Tamanho do texto */
  color: #ffffff;
  text-align: center; /* Centraliza o texto */
}

/* Ajuste especifico dos textos do carrossel */
.carousel-inner {
  align-items: center;
  min-height: 400px; /* Aumente se necessário para um melhor ajuste */
}

.carousel-item {
  text-align: center;
}

.carousel {
  max-width: 100%;
  align-items: center;
  margin: auto;
}

@media (max-width: 768px) {
  .carousel-caption {
    font-size: 3vw; /* Ajusta o tamanho da fonte em telas menores */
    padding: 10px; /* Menos padding em telas menores */
  }

  .carousel-caption h3 {
    font-size: 5vw; /* Ajusta o tamanho do título em telas menores */
  }

  .carousel-caption p {
    font-size: 4vw; /* Ajusta o tamanho do texto em telas menores */
  }

  .carousel-inner {
    min-height: 250px; /* Ajusta a altura mínima do carrossel em telas menores */
  }
}
